<template>
<div class="card-box-full" style="width: 770px; min-height:285px">
  <div class="card-box-header">
    <table width="100%">
      <tr>
        <td>
          <CDropdown color="secondary" :toggler-text="title">
            <CDropdownItem>Message Trend</CDropdownItem>
          </CDropdown>
        </td>
        <td align="right" style="padding-right: 15px; font-size: 13px">
          <span v-if="interval != '24h'" class="interval-link">24h</span>
          <span v-if="interval == '24h'" style="font-weight: bold">24h</span>
        </td>
      </tr>
    </table>
  </div>

  <div style="max-width: 800px">

    <!-- ----------------------------------------------------------------- -->
    <div v-if="errorMessage">
      <div class="alert alert-danger" style="margin-top: 15px; margin-bottom: 5px" role="alert">{{ errorMessage }}</div>
    </div>

    <!-- ----------------------------------------------------------------- -->
    <div v-else-if="isRefreshing && !data" style="text-align: center; margin-top: 100px">
      <div class="spinner-border spinner-border-lg text-primary" role="status">
        <span class="sr-only"></span>
      </div>
    </div>

    <!-- ----------------------------------------------------------------- -->
    <div v-else style="margin-top: 10px;">

      <div style="background-color: #f7f7f7; border-radius: 5px; width: fit-content; overflow: hidden; border: 0.5px solid #c4c9d0; cursor: crosshair">
        <table border="0" cellspacing="0">
          <tr v-for="minute in 60">
            <td v-for="hour in 24" style="padding: 0px">
              <div class="heatmap-cell" :style="divStyle(hour, minute)" @mouseover="hover(hour, minute-1)"></div>
            </td>
          </tr>
        </table>
      </div>

      <table width="100%">
        <tr>
          <td align="left" style="padding-left: 12px; color: #A0A0A0">▲ 24 Hours Ago<td>
          <td align="center" style="color: #A0A0A0; font-weight: bold">
            <span id="hover-time" class="badge badge-pill badge-secondary"></span>
            <span id="hover-value" class="badge badge-pill badge-danger" style="margin-left: 5px"></span></td>
          <td align="right" style="padding-right: 12px; color: #A0A0A0">Current Hour ▲</td>
        </tr>
      </table>

    </div>
  </div>
</div>
</template>

<style>
.heatmap-cell:hover {
  filter: drop-shadow(0px 0px 1px black)
}
</style>

<script>
export default {
  name: 'Daily-Heatmap',
  data() {
    return {
      dataTime: null,
      data: null,
      errorMessage: null,
      interval: "24h",
      isRefreshing: false,
      title: "Message Trend"
    }
  },
  methods: {
    //--------------------------------------------------------------------------
    hover(hour, minute) {
      var time = new Date(this.dataTime);
      time.setHours(time.getHours() - (24 - hour));
      time.setMinutes(minute);
      var options = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      };
      var blocked = this.data[23 - (hour - 1)][minute];
      document.getElementById("hover-value").style.backgroundColor = this.colorForValue(blocked);
      blocked = Math.round((1 - blocked) * 100);
      document.getElementById("hover-time").innerHTML = time.toLocaleString('en-US', options);
      if (blocked >= 50) {
        document.getElementById("hover-value").innerHTML = blocked + "% Blocked";
      } else {
        document.getElementById("hover-value").innerHTML = (100 - blocked) + "% Allowed";
      }
    },
    //--------------------------------------------------------------------------
    gradient(t, start, middle, end) {
      return t >= 0.5 ? this.linear(middle, end, (t - .5) * 2) : this.linear(start, middle, t * 2);
    },
    //--------------------------------------------------------------------------
    linear(s, e, x) {
      let r = this.byteLinear(s[1] + s[2], e[1] + e[2], x);
      let g = this.byteLinear(s[3] + s[4], e[3] + e[4], x);
      let b = this.byteLinear(s[5] + s[6], e[5] + e[6], x);
      return "#" + r + g + b;
    },
    //--------------------------------------------------------------------------
    byteLinear(a, b, x) {
      let y = (('0x' + a) * (1 - x) + ('0x' + b) * x) | 0;
      return y.toString(16).padStart(2, '0')
    },
    //--------------------------------------------------------------------------
    colorForValue(value) {
      if (value < 0) {
        return "transparent";
      } else {
        return this.gradient(value, '#FF0000', '#f0eaa6', '#029002');
      }
    },
    //--------------------------------------------------------------------------
    divStyle(hour, minute) {

      hour = 23 - (hour - 1);
      minute = minute - 1;
      var color = "transparent";

      if (this.data && this.data.length > hour) {
        if (this.data[hour].length > minute) {
          color = this.colorForValue(this.data[hour][minute]);
        }
      }

      return {
        width: "31px",
        height: "3px",
        background: color
      };
    },
    //--------------------------------------------------------------------------
    parseMessage(body) {
      try {
        var result = JSON.parse(body);
        return result.message;
      } catch (err) {
        return "";
      }
    },
    //--------------------------------------------------------------------------
    processResponse(response) {
      // Make sure we have data
      if (!response || !response.data) {
        this.errorMessage = "Server did not return any data";
        return;
      }

      this.dataTime = new Date();
      this.data = response.data;
    },
    //--------------------------------------------------------------------------
    refresh() {
      if (this.isRefreshing) {
        return;
      } else {
        this.errorMessage = null;
        this.isRefreshing = true;
      }

      // Fetch the heatmap
      var that = this;
      var method = "GET";
      var url = "https://sms-filter.platform.robocalls.ai/stats/heatmap?type=hourly";
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, null, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isRefreshing = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the accounts
            try {
              var result = JSON.parse(body);
              that.processResponse(result);
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isRefreshing = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isRefreshing = false;
          that.errorMessage = error;
        }
      })
    },
  },
  mounted() {
    this.refresh()
  },
}
</script>
